import React from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {AiFillInstagram} from 'react-icons/ai'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a31ibe6', 'template_kbdavzu', form.current, 'LDRQZMWx-3uW8dGhQ')
    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
        <article className="contact__option">
          <MdEmail className='contact__option-icon'/>
          <h4>Email</h4>
          <h5>rajesh1647290@gmail.com</h5>
          <a href="mailto:rajesh1647290@gmail.com" target='_blank'>Send a message</a>
        </article>

        <article className="contact__option">
          <AiFillInstagram className='contact__option-icon'/>
          <h4>Instagram</h4>
          <h5>Zeus._.0354</h5>
          <a href="https://www.instagram.com/zeus._.0354/" target='_blank'>Send a message</a>
        </article>

        <article className="contact__option">
          <BsWhatsapp className='contact__option-icon'/>
          <h4>WhatsApp</h4>
          <h5>967-649-5686</h5>
          <a href="https://wa.me/+919676495686" target='_blank'>Send a message</a>
        </article>

        </div>
      {/*End of Options*/}
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='name' placeholder='Your Full Name' required/>
        <input type="email" name='email' placeholder='Your Email' required/>
        <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>
      </form>
      </div>
    </section>
  )
}

export default Contact